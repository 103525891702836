import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";
import {
  createAuthor,
  editAuthor,
  getSingleAuthors,
} from "../../../services/dashboard";

import extractErrorMessage from "../../shared/basic/formateError";
import UploadFile from "../../ProductsComp/UploadFile";
import { generateUrlForImage } from "../../ProductsComp/Books";
import { Endpoints } from "../../../network";
import Cookies from "js-cookie";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  type?: string;
  authorId?: number;
}

const AuthorModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  type,
  authorId,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [formData, setFormData] = useState<{
    name: string;
    designation: string;
    avatar: any;
    description: string;
    longDescription: string;
    email: string;
  }>({
    name: "",
    designation: "",
    avatar: undefined,
    description: "",
    longDescription: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    designation: "",
    avatar: "",
    description: "",
    longDescription: "",
    email: "",
  });

  const defaultMode = Cookies.get("working_mode");

  const baseUrl =
    defaultMode === "live"
      ? process.env.REACT_APP_BASE_URL_LIVE
      : process.env.REACT_APP_BASE_URL;

  const handleCancel = () => {
    onCancel();
    setErrors({
      name: "",
      designation: "",
      avatar: "",
      description: "",
      longDescription: "",
      email: "",
    });
    setFormData({
      name: "",
      designation: "",
      avatar: undefined,
      description: "",
      longDescription: "",
      email: "",
    });
  };
  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleCancel();
    }, 2000);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      name: "",
      designation: "",
      avatar: "",
      description: "",
      longDescription: "",
      email: "",
    };
    if (!formData.name) {
      validationErrors.name = "Please Enter Author name";
    }
    if (!formData.designation.length) {
      validationErrors.designation = `Please Enter designation separated by ' | '`;
    }
    if (!formData.description.length) {
      validationErrors.description = `Please Enter description separated by ' | '`;
    }
    if (!formData.longDescription) {
      validationErrors.longDescription = `Please Enter long Description`;
    }
    if (!formData.email) {
      validationErrors.email = `Please Enter author's email`;
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setConfirmLoading(true);
        if (type !== "Edit") {
          await createAuthor({
            ...formData,
            description: formData?.description?.split(" | "),
            designation: formData?.designation?.split(" | "),
          });
        } else {
          await editAuthor(authorId?.toString() as string, {
            ...formData,
            description: formData?.description?.split(" | "),
            designation: formData?.designation?.split(" | "),
          });
        }

        notify("Author Created Successfully", "success");
        handleCancel();
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, "error");
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  // const handleAllProducts = async (type: string) => {
  //   const isLong = type === "long";
  //   try {
  //     const products = await getAllProducts({
  //       book_type: isLong ? "hardcover" : "digital",
  //       category_id: 6,
  //     });

  //     setProducts(
  //       products?.data?.map((product: any) => ({
  //         productId: isLong ? product?.digital_pair?.id : product?.id,
  //         hardcoverProductId: isLong ? product?.id : product?.id,
  //         digitalProductId: isLong ? product?.digital_pair?.id : product?.id,
  //         slug: product?.slug,
  //         name: product?.name,
  //       }))
  //     );
  //   } catch (error) {
  //     console.error("API call error:", error);
  //   }
  // };
  // useEffect(() => {
  //   if (formData.type && formData.type !== "") {
  //     handleAllProducts(formData.type);
  //   }
  // }, [formData.type]);

  const handleInputChange = (
    name: string,
    value: string | File | undefined | null
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSingleBook = async (authorId: string) => {
    try {
      //   setLoading(true);
      const response = await getSingleAuthors(authorId);
      const { data } = response;
      console.log(response, data, "alskjdlaks");
      setFormData({
        ...formData,
        name: data?.name,
        description: data?.description?.join(" | "),
        designation: data?.designation?.join(" | "),
        longDescription: data?.longDescription,
        email: data?.email,
        avatar: generateUrlForImage(
          `${baseUrl}`,
          `${Endpoints.getAuthorAvatar}/`,
          `${data?._id}`
        ),
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (authorId) {
      handleSingleBook(authorId.toString());
    }
  }, [authorId]);

  return (
    <Modal
      title=""
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={false}
      className="customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center flex-col justify-center px-5 md:px-10">
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className="pb-3 flex flex-col gap-y-2 ">
            <h1 className="text-xl flex justify-center gap-x-2 font-medium lg:font-normal lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
              {type} Author
            </h1>
          </div>

          <form className="flex flex-col mt-2 gap-y-3 w-full ">
            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="name"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.name}
                placeholder="Author Name"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.name && (
                <p className="text-red-500 text-xs">{errors.name}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="email"
                name="email"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.email}
                placeholder="Author email"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.email && (
                <p className="text-red-500 text-xs">{errors.email}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input.TextArea
                name="designation"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.designation}
                placeholder="Designation separated by '|'"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.designation && (
                <p className="text-red-500 text-xs">{errors.designation}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input.TextArea
                name="description"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.description}
                placeholder="Description separated by '|'"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.description && (
                <p className="text-red-500 text-xs">{errors.description}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input.TextArea
                name="longDescription"
                rows={2}
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.longDescription}
                placeholder="Long Description"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.longDescription && (
                <p className="text-red-500 text-xs">{errors.longDescription}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5 ">
              <UploadFile
                placeholder={`Click or drag to upload ${
                  type === "Edit" && "New"
                } Avatar`}
                image={formData.avatar ? formData.avatar : null}
                isSession={true}
                onFileChange={(file) => handleInputChange("avatar", file)}
                onImageRemove={() => handleInputChange("avatar", null)}
              />
              {errors.avatar && (
                <p className="text-red-500 text-xs">{errors.avatar}</p>
              )}
            </div>

            <span className="flex justify-center gap-x-2 mt-2 pb-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.GRAY}
                className="p-1 w-1/3"
                disabled={confirmLoading}
                loading={confirmLoading}
                rounded={true}
                onClick={() => handleCancel()}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className="p-1 w-2/3"
                disabled={confirmLoading}
                loading={confirmLoading}
                rounded={true}
                onClick={handleSubmit}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  {type} Author
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AuthorModal;
